.connect-image{
  padding: 0;
  margin: 0;
  position: relative;
}
.connect-image img{
  width:300px%;
  height:300px;
  margin: 0;
  padding: 0
}
.urlbar{
  width: 100%
}

#connect-footer p{
  font-size: 20px;
  margin-bottom: 0;
  background-color: black;
  color: white;
  vertical-align:middle;
}
