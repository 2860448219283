p,
div {
	font-family: Lato;
}

/*
  Colors:
  https://coolors.co/22223b-4a4e69-eadae4-9a8c98-c9ada7
*/
/*
YANKEES BLUE: #22223B
INDEPENDENCE: #4A4E69
PLATINUM: #EADAE4
SPANISH GRAY: #9A8C98
SILVER PINK: #C9ADA7
*/

#wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#form-wrapper {
	display: flex;
	flex-direction: column;
	width: 35rem;
	max-width: 80%;
	min-width: 100px;
	min-height: 400px;
	padding: 20px 40px;
	border-radius: 6px;
	box-shadow: 0px 8px 36px #222;
	background-color: #fefefe;
}

#form-wrapper > h2 {
	display: flex;
	justify-content: center;
	font-family: 'Segoe UI', 'Ubuntu', 'Roboto', 'Open Sans', 'Helvetica Neue',
		sans-serif;
	font-size: 2em;
	font-weight: lighter;
	margin-top: 0.25em;
	color: #222;
}

form {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.info {
	padding-bottom: 1em;
	padding-left: 0.5em;
	padding-right: 0.5em;
}

label {
	margin-bottom: 0.5em;
	color: #444;
	font-weight: lighter;
}

input {
	padding: 10px 10px;
	border-radius: 5px;
	outline: none;
	border: 1px solid #d6d1d5;
}

input::placeholder {
	font-size: 1.2em;
	font-weight: lighter;
	color: #bbb;
}

button {
	min-width: 100%;
	cursor: pointer;
	margin-right: 0.25em;
	margin-top: 0.5em;
	padding: 0.938em;
	border: none;
	border-radius: 4px;
	background-color: #22223b;
	color: #fefefe;
}
button:hover {
	background-color: #4a4e69;
	color: #fefefe;
}

.error {
	color: #db2269;
	font-size: 0.5em;
	display: relative;
}

.fullName,
.email,
.password {
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
}

.fullName {
	width: 100%;
}

.email,
.password {
	width: 100%;
}

.submit {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.password__show {
	align-items: right;
	position: relative;
	bottom: 30px;
}
