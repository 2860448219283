body {
  color: #161c2d;
  text-align: left;
  background-color: #fff;
}

#banner {
  margin-bottom: 0px !important;
}

@media only screen and (max-width: 489px) {
  body {
    text-align: justify;
  }
  .banner-img {
    margin-top: 35px;
  }
  .promo-title {
    margin-top: 4em;
    font-size: 30px !important;
  }
  .section-title {
    font-size: 40px;
  }
  .about-title {
    font-size: 29px !important;
  }
  .login {
    position: relative;
    text-align: center;
    margin: auto;
    padding: 0;
    padding-left: 0px;
    top: 5rem;
    right: 5rem;
  }
  .working {
    margin-bottom: 60px;
  }
  #work .direction:before {
    font-family: FontAwesome;
    position: absolute;
    font-size: 25px;
    color: black;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    content: "\f061";
    transform: translate(-50px, -215px);
    rotate: 90deg;
    margin: auto !important;
  }
  .about-us {
    margin-left: 0 !important;
  }
  section {
    padding: 0 !important;
  }
  .feedPara-div {
    width: 100% !important;
  }
  #feedback {
    padding: 60px 0 !important;
  }
  .feedPara-div .feedback-para {
    width: 100%;
    text-align: justify;
  }
  .switch {
    float: none !important;
  }
  .switch-para {
    float: none !important;
  }
  .footer-img {
    width: auto !important;
  }
  .footer-logo {
    margin-top: -100px;
    height: 250px;
  }
  .footer-social {
    float: left !important;
    margin-top: 20px;
    margin-left: 90px;
  }
  .affliliateBtn {
    margin-top: -3em;
    width: 100%;
  }

  .copyright {
    float: none !important;
  }
  .footer-company {
    margin-bottom: 10px;
  }
  .footer-company a {
    margin-left: 9px;
    margin-right: 0 !important;
    float: none !important;
  }
}

@media only screen and (max-width: 419px) {
  body {
    text-align: justify;
  }
  .promo-title {
    margin-top: 4em;
    font-size: 30px !important;
  }
  .section-title {
    font-size: 40px;
  }
  .about-title {
    font-size: 29px !important;
  }
  .login {
    position: relative;
    text-align: center;
    margin: auto;
    padding: 0;
    padding-left: 0px;
    top: -25rem;
    right: 15rem;
  }
  .banner-img {
    margin-top: 35px;
  }
  .working {
    margin-bottom: 60px;
  }
  #work .direction:before {
    font-family: FontAwesome;
    position: absolute;
    font-size: 25px;
    color: black;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    content: "\f061";
    transform: translate(-50px, -180px);
    rotate: 90deg;
    margin: auto !important;
  }
  .about-us {
    margin-left: 0 !important;
  }
  section {
    padding: 0 !important;
  }
  .feedPara-div {
    width: 100% !important;
  }
  #feedback {
    padding: 60px 0 !important;
  }
  .feedPara-div .feedback-para {
    width: 100%;
    text-align: justify;
  }
  .switch {
    float: none !important;
  }
  .switch-para {
    float: none !important;
    height: 100px !important;
  }

  .footer-img {
    width: auto !important;
  }
  .footer-logo {
    margin-top: -50px;
    height: 200px;
  }
  .footer-social {
    float: left !important;
    margin-top: 20px;
    margin-left: 50px;
  }
  .affliliateBtn {
    margin-top: -3em;
    width: 100%;
  }

  .copyright {
    float: none !important;
  }
  .footer-company {
    margin-bottom: 10px;
  }
  .footer-company a {
    margin-left: 9px;
    margin-right: 0 !important;
    float: none !important;
  }
}

/*Banner Section*/

#banner {
  padding-top: 20%;
}

.promo-title {
  margin-top: 10px;
  font-size: 40px;
}
.signup-banner {
  background-color: #1fa8cf; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.signup-banner:hover {
  background-color: #1fa8cf; /* Green */
}
/**********************
/***** Services *******
/*********************/
#Features {
  padding: 80px 0;
}

.feature-icon {
  width: 100%;
  height: 50px;
  margin-top: 20px;
  overflow: hidden;
  vertical-align: middle;
}

.features {
  padding: 20px;
}

.features h4 {
  padding: 5px;
  margin-top: 25px;
  text-transform: uppercase;
}
.features p {
  color: #869ab8 !important;
}
/*How does it work*/
.section-title {
  color: yellowgreen;
}
.work-flow {
  padding: 60px 0;
}

#work .direction {
  width: 4%;
  height: 1px;
  float: left;
}

#work .direction:before {
  font-family: FontAwesome;
  position: absolute;
  font-size: 25px;
  color: black;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  content: "\f061";
  margin: 2rem;
}
/*New Section`*/
form .fields-blank {
  color: dark gray;
  border: none;
}

form .fields-blank::placeholder {
  color: #696969;
}

.about-title {
  font-size: 40px;
  font-weight: 300;
  margin-top: 8%;
  color: #000080;
}
.about-us {
  margin-left: 8rem;
}
.about-us ul li {
  margin: 20px 0;
  font-size: 15px;
  list-style: none;
  font-weight: lighter;
}

.about-us ul li:before {
  color: yellowgreen;
  content: "\f00c"; /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -2.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
}

/*New Section*/
.service-title {
  font-size: 40px;
  font-weight: 300;
  margin-top: 8%;
}

#services .card {
  padding: 1rem !important;
  border: none;
  margin-bottom: 1rem;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
#services .card:hover {
  -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}
#services .card .card-block {
  padding-left: 50px;
  position: relative;
}
#services .card .card-block a {
  color: #007b5e !important;
  font-weight: 700;
  text-decoration: none;
}
#services .card .card-block a i {
  display: none;
}
#services .card:hover .card-block a i {
  display: inline-block;
  font-weight: 700;
}
#services .card .card-block:before {
  font-family: FontAwesome;
  position: absolute;
  font-size: 39px;
  color: #1c86ee;
  left: 0;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}
#services .card .block-1:before {
  content: "\f0e7";
}
#services .card .block-2:before {
  content: "\f0eb";
}
#services .card .block-3:before {
  content: "\f00c";
}
#services .card .block-4:before {
  content: "\f209";
}
#services .card .block-5:before {
  content: "\f0a1";
}
#services .card .block-6:before {
  content: "\f218";
}
#services .card:hover .card-block:before {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.card {
  box-shadow: none !important;
  border-radius: 0;
}

/*feedback Section*/
#feedback h1 {
  font-size: 30px;
  font-weight: 300;
}
.feedback-para {
  font-size: 20px;
  font-weight: lighter;
}
.feedPara-div {
  width: 50%;
}

/*pricing section*/

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.card-pricing.popular {
  z-index: 1;
  border: 3px solid #007bff;
}
.card-pricing .list-unstyled li {
  padding: 0.5rem 0;
  color: #6c757d;
}
.price {
  font-size: 20px;
}

.tickMark .extra:before {
  color: yellowgreen;
  content: "\f00c"; /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -2.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
}

/*footer section*/

.footer-top-layout {
  background: #1c1c1c none repeat scroll 0 0;
  color: #ffffff;
  padding: 40px 0;
}
.OurBlog {
  margin-bottom: 32px;
  text-align: center;
}
.footer-top-layout h4 {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 21px;
}
.OurBlog > p {
  color: #888888;
}
.post-blog-date::after {
  background: #ffffff none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 4px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  width: 50px;
}
.post-blog-date {
  padding-bottom: 25px;
  position: relative;
}

/* new landing css starts */
.mainContainer {
  background: #19489e;
  width: 100%;
  position: relative;
}
.wave-flip {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: scaleX(-1);
  z-index: 0;
}
header {
  /* padding: 20px 0; */
  display: flex ;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.dropdown {
  z-index: 3;
}
.dropdown .dropdown-toggle {
  color: #fff;
  font-weight: 500;
  text-decoration: underline;
  font-size: 16px;
}
.dropdown-menu {
  padding: 10px 0 !important;
}
.dropdown-item svg {
  margin-right: 10px;
}
.mainBanner {
  padding: 40px 0 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mainBanner .mainBanner__content {
  width: 55%;
  color: #fff;
}
.mainBanner .mainBanner__content h1 {
  font-weight: 700;
  font-size: 65px;
  color: #fff;
}
.mainBanner .mainBanner__content p {
  font-weight: 400;
  font-size: 20px;
}
.mainBanner .mainBanner__content button {
  background: #ffa000;
  color: #fff;
  font-weight: 400;
  opacity: 1;
  text-transform: uppercase;
}
.mainBanner .mainBanner__img {
  width: 40%;
}
.mainBanner .mainBanner__img img {
  border-radius: 100%;
  width: 100%;
  max-width: 400px;
  position: relative;
  z-index: 1;
  object-fit: cover;
  border: 2px solid #ffa000;
}
#seller-moreInfo h3 {
  padding-top: 40px;
  font-weight: 500;
}
#seller-moreInfo h4 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 500;
}
.footerSpace {
  margin: 40px 0 10px;
  background: inherit !important;
  text-align: center;
}
#seller-moreInfo ul {
  margin: 0;
  padding: 0;
}
#seller-moreInfo ul li {
  list-style-type: none;
  font-size: 14px;
  margin-top: 5px;
}
#seller-moreInfo ul li a {
  color: inherit;
}
/* new landing css ends */
