.showContent>span{
  top: 7px;
font-size: 18px;
position: relative;
display: block;
}

.showContentChanged>span{
  top: 4px;
font-size: 18px;
position: relative;
display: block;
margin-bottom: 0px;
}

.role-ul{
  font-size: 17px;
text-align: justify;
margin-top:20px;
}
.role-ul-changed{
  font-size: 17px;
text-align: justify;
margin-top:-20px;
}

.role-li{
  margin-top: 15px;
}
.role-li>span{
  margin-left: 1rem;
text-align: justify;
display: block;
}
