p,
div {
	font-family: Lato;
}

#parent {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 25px 25px 25px 25px;
	/* align-items: center; */
	/* justify-content: center; */
}
.label {
	margin-bottom: 0.5em;
	color: black;
	font-weight: lighter;
	font-size: medium;
}
.labeldiv {
	align-items: center;
	padding: 5px 5px 5px 5px;
}
#formdiv {
	display: flex;
	flex-direction: row;
	align-items: center;
	/* justify-content: center; */
	padding: 20px 20px 20px 20px;
}
.container {
	width: 100%;
	align-items: center;
}
