.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover{
	background-color: #1DC7EA !important;
    border-color: #1DC7EA !important;
    color : white !important;
}
.pagination>li>a, .pagination>li>span{
	color : #1DC7EA !important;
	background-color: #fff !important;
	border: 1px solid #ddd !important;
}
.modal-header>.close{
	float: right;
    text-align: right;
    position: absolute;
    line-height: 38px;
    right: 15px;
    opacity: 1;
    font-weight: 600;
}
.modal-header>.close>span{
	font-size: 30px;
}
.cursor-poiner{
	    cursor: pointer !important;
}
.planModal>.modal-dialog{
    width: 60% !important;
}

.sign-up-form p{
    width: 100%  !important;
    
}
