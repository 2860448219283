ol,
ul,
li {
	list-style: none;
}

body,
button,
input,
select,
textarea, p, div, b, i, dl, dt, dd, .ct-labels span {
	font-family: "Roboto","Helvetica Neue",Arial,sans-serif !important;
	outline: none;
}

.ct-labels span {
	font-family: "Roboto","Helvetica Neue",Arial,sans-serif !important;
	outline: none;
	font-weight: normal;
}

.fa{
	font-family: FontAwesome !important;
}

 
.index-banner-main .index-banner-01 {
	height: 644px;
	background: #19489e no-repeat center top;
}

.container {
	padding: 0;
}

.top-index {
	width: 100%;
	height: auto;
	position: relative;
}

.top-index .navbar {
	min-height: 34px;
	margin: 0;
}

.top-index .navbar-default {
	background: 0 0;
	border: 0;
	border-radius: 0;
	margin-top: 27px !important;
	z-index: 2;
}

.top-index .navbar {
	min-height: 34px;
	margin: 0;
}

.top-index .container-fluid {
	padding: 0;
}

.top-index .navbar-brand {
	margin-left: 0!important;
	padding: 0 !important;
	height: 34px;
	line-height: 34px;
}

.index-banner-main .index-banner-01 {
	height: 644px;
	background: #19489e no-repeat center top;
}

.common-btn,
.common-btn2,
.common-btn3,
.common-btn4 {
	position: relative;
	display: inline-block;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	cursor: pointer;
	user-select: none;
	border: none;
	border-radius: 4px;
	color: #fff;
	font-size: 14px;
	-webkit-transition: background-color .2s ease-in-out;
	-o-transition: background-color .2s ease-in-out;
	transition: background-color .2s ease-in-out;
}

.common-btn7 {
	margin-top: 25px;
	padding: 7px 34px;
	font-size: 24px;
	text-transform: capitalize;
}

.swiper-container {
	margin: 0 auto !important;
	position: relative;
	overflow: hidden !important;
	list-style: none;
	padding: 0 !important;
	z-index: 1;
}

.top-index .swiper-container {
	display: inline-block;
}

.swiper-wrapper {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	-o-transition-property: transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}

.index-banner-main {
	width: 100%;
	margin-top: -95px!important;
	overflow: hidden;
}

.index-banner-main .container {
	height: 644px;
	position: relative;
}

.index-banner-main .container h1 {
	font-size: 42px;
	font-weight: 400;
	color: #fff;
	line-height: 60px;
}

.index-banner-main .container p {
	margin-top: 10px;
	font-size: 16px;
	color: #fff;
	line-height: 24px;
}

.index-banner-main .container .btn-color1 {
	background: #fff;
	color: #3b396d;
	transition: .2s;
}

.index-banner-main .container .btn-color1:hover {
	background: #3b396d;
	color: #fff;
	transition: .2s;
}

.index-banner-main .container .common-btn7 {
	margin-top: 30px;
}

.index-banner-main .index-banner-01 {
	height: 644px;
	background: #19489e no-repeat center top;
}

.index-banner-main .index-banner-01 .introduce {
	padding: 0;
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -121px;
	text-align: left;
}

.index-banner-main .index-banner-01 .banner-right-img {
	padding: 0;
	position: absolute;
	top: 50%;
	right: -25px;
	margin-top: -145px;
	text-align: right;
}

.index-banner-main .index-banner-02 {
	height: 644px;
	background: url(/app/images/home/index02.png) no-repeat center top;
	position: relative;
}

.index-banner-main .index-banner-02 .container {
	height: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	margin: auto;
	text-align: center;
	color: #fff;
}

.index-banner-main .index-banner-02 .container b {
	display: inline-block;
	font-size: 50px;
	margin-bottom: 35px;
	font-weight: 400;
}

.index-banner-main .index-banner-02 .container p {
	font-size: 22px;
	line-height: 40px;
}

.index-banner-main {
	padding: 0;
	margin: 0;
}

@keyframes slidy {
	0% { left: 0%; }
	20% { left: 0%; }
	25% { left: -100%; }
	45% { left: -100%; }
	50% { left: -200%; }
	70% { left: -200%; }
	75% { left: -300%; }
	95% { left: -300%; }
	100% { left: -400%; }
	}
	
	body { margin: 0; } 
	div#slider { overflow: hidden; }
	div#slider figure img { width: 20%; float: left; }
	div#slider figure { 
	  position: relative;
	  width: 500%;
	  margin: 0;
	  left: 0;
	  text-align: left;
	  font-size: 0;
	  animation: 30s slidy infinite; 
	}
	

.index-banner-main .col-md-6 {
	padding: 0;
}

.index-banner-main .col-md-7 {
	padding: 0;
}

.index-banner-main .col-md-3 {
	padding: 0;
}

.navbar-default .navbar-toggle .icon-bar {
	background-color: #fff;
}
.navbar-toggle {
	position:relative;
	float:right;
	padding:9px 10px;
	background-color:transparent;
	background-image:none;
	border:1px solid transparent;
	border-radius:4px;
	display: none !important;
	height:inherit !important;
	width: inherit !important;
	min-width: inherit !important;
}

.navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
}

.index-banner-main .swiper-pagination {
	right: 20px;
	top: 50%;
}

.index-banner-main .swiper-pagination-bullet {
	display: block;
	margin-bottom: 10px;
	background: #fff;
}

.top-index .navbar-nav {
	float: right;
}

.top-index .nav>li {
	padding: 0 15px;
}

.top-index .nav>li>a {
	padding: 0;
	text-transform: capitalize;
	transition: .1s;
	height: 34px;
	line-height: 34px;
}

.top-index .navbar-default .navbar-nav>li>a {
	color: #fff !important;
	font-size: 16px;
	padding: 0;
	margin: 0;
}

.top-index .navbar-nav>li .sign_up {
	width: 80px;
	height: 24px;
	margin: 5px 0 0 0 !important;
	padding: 0;
	background: #fff;
	border-radius: 12px;
	color: #3b396d !important;
	line-height: 24px;
	text-align: center;
}

.top-index .navbar-nav>li> .sign_up:hover {
	background: #706dc1;
	color: #fff;
}

.dropdown-item{
	display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.navbar-default .navbar-nav>li>a:before {
	content: ' ';
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	height: 2px;
	width: 0;
	background: #fff;
	transition: all .2s ease;
}

.navbar-default .navbar-nav>li>a:hover {
	color: #fff;
}

.navbar-default .navbar-nav>li>a:not(.shop-car):not(.e-mail):not(.dropdown-toggle):not(.sign_up):hover:before {
	width: 20px;
}

.top-index .navbar-default .navbar-nav li em {
	display: inline-block;
	width: 1px;
	height: 15px;
	background: #fff;
	float: left;
	margin-top: 9px;
}

.top-index .navbar-nav>li>a.sign_up:hover {
	background: #706dc1;
	color: #fff;
}

.top-index .navbar-nav .icons {
	display: block;
	background: url(/app/images/icons.png) no-repeat;
}

.top-index .navbar-nav .shop-car-icon {
	width: 20px;
	height: 20px;
	margin-top: 7px;
	background-position: -10px -44px;
}

.top-index .navbar-nav .shop-car {
	position: relative;
}

.shop_num {
	display: block;
	width: 22px;
	height: 22px;
	border-radius: 22px;
	position: absolute;
	top: -12px;
	right: -15px;
	background: #706dc1;
	color: #fff;
	font-size: 12px;
	text-align: center;
	line-height: 22px;
}

.top-index .navbar-nav .e-mail-icon {
	width: 18px;
	height: 20px;
	margin-top: 7px;
	background-position: -117px -44px;
}

.top-index .navbar-nav a.dropdown-toggle.user {
	width: 34px;
	height: 34px;
	background: #706dc1;
	border-radius: 34px;
	text-align: center;
	font-size: 14px;
	line-height: 34px;
}

.top-index .navbar-nav a.dropdown-toggle.user:focus,
.top-index .navbar-nav a.dropdown-toggle.user:hover {
	background: #706dc1;
	color: #fff;
}

.top-index .dropdown-menu {
	min-width: 135px;
	left: -35px;
	padding: 8px 16px;
	border-radius: 12px;
	border: 1px solid #e2e2e2;
}

.top-index .navbar-nav>li>.dropdown-menu {
	border-radius: 12px;
}

.fixnav-wrap {
	width: 100%;
	position: fixed;
	top: -70px;
	background: #fff;
	min-height: 66px;
	-moz-box-shadow: 0 2px 0 #e2e2e2;
	-webkit-box-shadow: 0 2px 0 #e2e2e2;
	box-shadow: 0 2px 0 #e2e2e2;
	transition: .2s;
	z-index: 100;
}

.fixnav-wrap-top {
	transition: .2s;
}

.fixnav-wrap .fixnav {
	width: 1200px;
	margin: 0 auto;
}

.fixnav-wrap .fixnav .navbar-brand {
	margin-top: 16px;
}

.fixnav-wrap .fixnav .nav>li {
	margin-top: 16px;
}

.fixnav-wrap .fixnav .nav>li>a {
	font-size: 16px;
}

.fixnav-wrap .fixnav .nav li em {
	display: inline-block;
	width: 1px;
	height: 15px;
	background: #000;
	float: left;
	margin-top: 9px;
}

.fixnav-wrap .fixnav .nav>li a.shop-car {
	height: 24px;
}

.fixnav-wrap .fixnav .nav>li a.e-mail {
	height: 20px;
}

.fixnav-wrap .fixnav .nav>li a.user {
	height: 34px;
	line-height: 34px;
	color: #fff;
}

.fixnav-wrap .fixnav .navbar-nav .shop-car-icon {
	background-position: -10px -10px;
}

.fixnav-wrap .fixnav .navbar-nav .e-mail-icon {
	background-position: -152px -44px;
}

.fixnav-wrap .fixnav .navbar-nav>li>a.sign_up {
	width: 80px;
	height: 24px;
	margin: 5px 0 0 0;
	padding: 0;
	background: #19489e;
	border-radius: 12px;
	color: #fff;
	line-height: 24px;
	text-align: center;
}
.fixnav-wrap .fixnav .navbar-nav>li>a{
	color: #3b396d;
}

.fixnav-wrap .fixnav .navbar-nav>li>a:focus,
.fixnav-wrap .fixnav .navbar-nav>li>a:hover {
	color: #3b396d;
	background: 0 0;
	transition: .1s;
}
.fixnav-wrap .fixnav .navbar-nav>li>a.sign_up{
	background: #19489e;
    color: #fff !important;
}

.partner-logo {
	width: 100%;
	height: 160px;
	padding: 0;
	position: absolute;
	left: 0;
	margin-top: -60px;
	z-index: 3;
	overflow: hidden;
}

.partner-logo ul{
	margin: 0;
	padding: 0;
}

.partner-logo li {
	width: 120px;
	height: 120px;
	float: left;
	margin: 0 40px 40px 30px;
	border-radius: 100%;
	background: #fff;
	box-shadow: 0 15px 40px 0 rgba(0, 0, 0, .18);
	text-align: center;
	vertical-align: middle;
	position: relative;
}

.partner-logo li img {
	vertical-align: middle;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.partner-logo li p {
	margin-top: 125px;
}

.owl-carousel.owl-drag .owl-item, .owl-carousel.owl-drag .item {
	width: 120px !important;
	height: 120px;
	float: left;
	margin: 0 40px 40px 30px !important;
	border-radius: 100%;
	background: #fff;
	box-shadow: 0 15px 40px 0 rgba(0, 0, 0, .18);
	text-align: center;
	vertical-align: middle;
	position: relative;
}

.owl-carousel.owl-drag .owl-item img {
	vertical-align: middle;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.owl-carousel.owl-drag .owl-item p {
	margin-top: 125px;
}

#picMarquee-right {
	position: relative !important;
	margin: 0;
}

#picMarquee-right img {
	width: 75px;
	border-radius: 6%;
}

span.product-item {
	position: absolute;
	bottom: -30px;
	width: 120px;
	left: 0;
	font-weight: 700;
	font-size: 12px;
}

.tempWrap {
	overflow: hidden;
	position: relative;
	width: 1900px;
}

.tempWrap ul {
	width: 3990px;
	position: relative;
	overflow: hidden;
	padding: 0px;
	margin: 0px;
	left: -581px;
}

.index-content {
	margin-top: 220px;
}

.index-content .row {
	padding: 0;
	margin: 0;
}

.index-content h2,
.common-pricing h2,
.pro-list h2 {
	font-size: 36px;
	font-weight: 700;
	text-align: center;
	color: #000;
}

.index-content .lead,
.common-pricing .lead {
	font-size: 16px;
	text-align: center;
	margin: 10px 0 30px;
	color: #000;
}

.index-content .lead-more {
	cursor: pointer;
	margin: 10px 0 20px;
}

.index-content .lead-more span {
	display: inline-block;
	margin-left: 10px;
	font-size: 14px;
	color: #333;
}

.index-content .lead-more-con {
	margin-bottom: 30px;
	padding: 0 15px;
	display: none;
}

.index-content .lead-more-con p {
	font-size: 14px;
	color: #333;
	line-height: 22px;
}

.index-content .lead-more-con p span {
	display: inline-block;
	top: 4px;
	margin-left: 10px;
	font-size: 14px;
	color: #333;
	cursor: pointer;
}

.index-content .video-wrap {
	margin-top: 1%;
}

.index-content .video-wrap ul li {
	float: left;
	width: 50%;
	margin-bottom: 10%;
}

.index-content .video-wrap ul li img {
	float: left;
	margin-right: 4%;
}

.index-content .video-wrap ul li .video-info,
.index-content .video-wrap ul li .video-info p {
	margin: 2% 4% 0 0;
	font-size: 14px !important;
}

.index-content .video-wrap ul li .video-info b {
	font: 14px "Helvetica";
	font-weight: 700;
}

.index-content .video-2 {
	margin-top: 190px;
}

.ourParnters {
	margin: 100px 0px 50px;
	font-size: 36px;
	font-weight: bold;
	text-align: center;
}

.common-pricing {
	background: #fff;
}

.common-pricing .table thead tr td {
	padding: 18px 40px;
	font-size: 20px;
	vertical-align: middle;
}

.common-pricing .table thead tr td:first-child {
	font-weight: 700;
	color: #7b999e;
	text-align: left;
}

.common-pricing .table thead tr td i {
	font-size: 16px;
	font-style: normal;
}

.common-pricing .table tbody tr td {
	padding: 12px 40px;
	font-size: 14px;
	color: #333;
	vertical-align: middle;
}

.common-pricing .table tbody tr td:first-child {
	text-align: left;
	position: relative;
}

.common-pricing .pricing-query-value {
	width: 16px;
	height: 16px;
	position: absolute;
	top: 13px;
	right: 10px;
}

.common-pricing .pricing-feature-value svg {
	width: 18px;
	height: 18px;
	fill: #19489e;
}

.common-pricing .pricing-query-value svg {
	width: 16px;
	height: 16px;
	fill: #3b396d;
}

.common-pricing .pricing-query-value i {
	width: 16px;
	height: 16px;
	fill: #3b396d;
}

.common-pricing .query-explain {
	display: none;
	position: absolute;
	top: -24px;
	left: 15px;
	width: 200px;
	padding: 9px;
	background: #464646;
	border: 1px solid #e7eef0;
	border-radius: 4px;
	font-size: 14px;
	color: #fff;
	line-height: 22px;
}

.common-pricing .pricing-query-value:hover .query-explain {
	display: block;
}

.common-pricing .table-bordered {
	margin-bottom: 0;
	border: 1px solid #e7eef0;
}

.common-pricing .table-bordered>thead>tr>td,
.common-pricing .table-bordered>thead>tr>th {
	border-bottom-width: 0;
}

.common-pricing .table-bordered>tbody>tr>td,
.common-pricing .table-bordered>tbody>tr>th,
.common-pricing .table-bordered>tfoot>tr>td,
.common-pricing .table-bordered>tfoot>tr>th,
.common-pricing .table-bordered>thead>tr>td,
.common-pricing .table-bordered>thead>tr>th {
	border: 1px solid #e7eef0;
}

.common-pricing .table tbody tr:last-child td {
	border: none;
}

.common-pricing .table tbody tr:last-child td a {
	display: inline-block;
	padding: 7px 21px;
	color: #fff;
	font-size: 14px;
	text-transform: capitalize;
	border-radius: 4px;
}

.common-pricing h3 {
	font-size: 32px;
	font-weight: 700;
	text-align: center;
	color: #000;
}

.common-pricing .sign {
	margin: 0 auto;
	display: table;
}

.common-pricing .pricing-sign {
	display: inline-block;
	padding: 7px 24px;
	font-size: 24px;
	background: #16d8b3;
	color: #fff;
	border: none;
	margin-top: 25px;
	border-radius: 4px;
}

.btn-color1 {
	background-color: #19489e;
}

.selectPlan {
	font-size: 20px;
	font-weight: bold;
	color: #7b999e;
}

.pricingTip dfn {
	position: relative;
}

.pricingTip dfn:hover em {
	display: block;
}

.pricingTip em {
	display: none;
	position: absolute;
	top: -24px;
	left: 15px;
	width: 200px;
	padding: 9px;
	background: #464646;
	border: 1px solid #e7eef0;
	border-radius: 4px;
	font-size: 14px;
	color: #fff;
	line-height: 22px;
	font-style: normal;
}

.fix-right {
	width: auto;
	width: 50px;
	height: auto;
	position: fixed;
	right: 0;
	top: 43%;
	z-index: 101;
	display: none;
}

.fix-right a {
	display: block;
	height: 50px;
	line-height: 50px;
	margin-bottom: 10px;
	background: #19489e;
	color: #fff;
	font-size: 14px;
	border-radius: 10px 0 0 10px;
}

.fix-right .mail-before {
	position: relative;
	height: 50px;
	margin-bottom: 10px;
}

.fix-right .mail-before a {
	width: 145px;
	position: absolute;
	right: -95px;
	transition: .2s;
}

.fix-right a:hover {
	background: #3b396d;
	right: 0;
	transition: .2s;
}

.fix-right .back-top a {
	width: 50px;
	margin-right: 0;
}

.fix-right .icons {
	display: block;
	background: url(/app/images/icons.png) no-repeat;
	float: left;
}

.fix-right .mail-icon {
	width: 25px;
	height: 20px;
	margin: 15px 14px 0 13px;
	background-position: -44px -44px;
}

.fix-right .top-icon {
	width: 28px;
	height: 28px;
	margin: 11px 14px 0 12px;
	background-position: -79px -44px;
}

.footerLogoWord {
	color: #f4f8f9;
	padding-left: 10px;
	font-size: 14px;
}

.footerWord dl {
	padding-left: 10px;
}

.footerWord dt {
	font-size: 20px;
}

.footerWord dd {
	color: #f4f8f9;
	line-height: 30px;
	font-size: 14px;
}

.footerWord dd a {
	color: #fff;
	font-size: 14px;
	text-transform: capitalize;
}

.footerWord dd a:hover {
	text-decoration: underline;
}

footer {
	background: #19489e;
	color: #fff;
}

.bottom {
	color: #babebd;
}

footer .col-md-12,
footer .col-md-4,
footer .col-md-3 {
	padding: 0;
	margin: 0;
}

.footer-copyright {
	width: 100%;
	height: 35px;
	line-height: 35px;
	text-align: center;
	color: #efefef;
}

.clear50 {
	clear: both;
	height: 50px;
}

.clear20 {
	clear: both;
	height: 20px;
}

@media(max-width:800px) {
	.top-index .swiper-container {
		display: block;
	}
	.navbar-collapse {
		box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .19);
	}
	.fixnav-wrap .fixnav .nav>li {
		margin-top: 0;
	}
	.top-index .navbar-default .navbar-nav li em {
		display: none;
	}
	.fixnav-wrap .fixnav .nav li em {
		display: none;
	}
	.index-banner-main {
		margin-top: -60px !important;
	}
	.partner-logo {
		display: none;
	}
	.banner-right-img {
		display: none;
	}
	.index-banner-main .index-banner-01 {
		height: 2.6rem;
		background: url(/app/images/home/index01.jpg) no-repeat center;
		background-size: cover;
	}
	.index-banner-main .index-banner-02 {
		height: 2.6rem;
		background: url(/app/images/home/index02.png) no-repeat center;
		background-size: cover;
	}
	.index-banner-main .container {
		height: 2.6rem;
	}
	.index-banner-main .index-banner-02 .container {
		width: 100%;
		height: 100%;
		position: relative;
	}
	.index-banner-main .index-banner-02 .container b {
		margin-bottom: 0;
		font-size: 22px;
		position: absolute;
		top: .8rem;
		left: 5px;
		right: 5px;
	}
	.index-banner-main .index-banner-02 .container p {
		font-size: 16px;
		line-height: .24rem;
		position: absolute;
		top: 1.1rem;
		left: 5px;
		right: 5px;
	}
	.index-banner-main .index-banner-02 .container p.none {
		display: none;
	}
	.index-banner-main .index-banner-01 .introduce {
		width: 100%;
		margin-top: -.5rem;
		text-align: center;
	}
	.index-banner-main .index-banner-02 .introduce {
		width: 100%;
		margin-top: -.5rem;
		text-align: center;
	}
	.index-banner-main .container h1 {
		font-size: .22rem;
		line-height: .28rem;
	}
	.index-banner-main .index-banner-01 .container p {
		display: none;
	}
	.index-banner-main .container .common-btn7 {
		display: table;
		margin: .18rem auto 0;
		padding: .08rem .2rem;
		font-size: .2rem;
	}
	.swiper-pagination {
		display: none;
	}
	footer {
		display: none;
	}
	.pro-list ul li.shade-outer .shade {
		display: none;
	}
}

@media(max-width:320px) {
	.index-banner-main .index-banner-02 .container b {
		position: absolute;
		top: .65rem;
		left: 5px;
		right: 5px;
	}
	.index-banner-main .index-banner-02 .container p {
		position: absolute;
		top: 1.2rem;
		left: 5px;
		right: 5px;
	}
}

@media(max-width:768px) {
	
	.navbar-toggle:focus {
		outline:0
	}
	.top-index .navbar-nav>li>a {
		padding: 0 !important;
		margin: 0 !important;
	}
	.top-index .container-fluid>.navbar-collapse {
		padding: 0;
	}
	.top-index .navbar-default{margin-top:10px}
	.container-fluid>.navbar-header{padding:8px 15px;margin:0}
	.navbar-default .navbar-collapse,.container-fluid>.navbar-collapse{width:100%;margin:0;position:absolute;left:0;background:#33383e;color:#000;z-index:10;padding:0}

	.fixnav-wrap .fixnav{width:100%}
	.navbar-toggle .icon-bar{background:#000 !important;}
	.fixnav-wrap .fixnav .navbar-brand{margin-top:6px}
	.fixnav-wrap .fixnav .navbar-toggle{margin:6px 0 0}
	.top-index .row{margin:1% 0 6.5%}
	.top-index .navbar-nav{float:left;width:100%;margin:0}
	.top-index .nav>li{border-bottom:1px solid #4a4949;width:100%}
	.top-index .navbar-default .navbar-nav>li>a{font-size:14px}
	.top-index .nav>li>a{height:auto;line-height:30px;padding:8px 0}
	.top-index .navbar-nav .shop-car-icon,.fixnav-wrap .fixnav .navbar-nav .shop-car-icon{height:30px;background-position:-10px -44px}
	.top-index .navbar-nav .e-mail-icon,.fixnav-wrap .fixnav .navbar-nav .e-mail-icon{height:30px;background-position:-117px -44px}
	.top-index .row .col-md-6{width:auto;padding:24px}
	.top-index .row .col-md-6 h1{font-size:30px;line-height:35px}
	.top-index .row .col-md-6 p{font-size:14px;line-height:22px}
	.top-index .row .col-md-6 .btn-default{background:#16d8b3;color:#fff;border:none;margin-top:25px}
	.top-index .btn{padding:5px 15px;font-size:18px}.hot_sale{display:none}.index-content{margin-top:30px}
	.index-content .lead{font-size:14px;margin:5px 0 10px}
	.index-content .video-wrap{margin-top:3%}
	.index-content .video-wrap ul li{width:100%;margin-bottom:3%}
	.index-content .video-2{margin-top:30px}.pro-list{margin:50px 0 0}
	.pro-list ul li{width:20%}
	.pro-title{display:none}
	.index-content h2,.common-pricing h2,.pro-list h2{font-size:28px}
	.pro-list h2{margin-bottom:30px}.common-pricing .lead{font-size:14px}
	.common-pricing{margin-top:30px}
	.fixnav-wrap .fixnav .nav>li>a{color:#fff;height:auto;line-height:30px;font-size:14px}
	.fixnav-wrap .fixnav .nav>li:nth-child(4)>a,.top-index .navbar-nav>li:nth-child(4)>a{border-right:none;margin:0;height:auto;line-height:30px}
	.fixnav-wrap .fixnav .nav>li a.shop-car,.fixnav-wrap .fixnav .nav>li a.e-mail,.fixnav-wrap .fixnav .nav>li a.user{height:auto;margin-top:0}
	.navbar-default .navbar-nav .open .dropdown-menu>li>a{color:#fff}
	.top-index .dropdown-menu li .account-icon{background-position:-238px -13px}
	.top-index .dropdown-menu li .orders-icon{background-position:-259px -13px}
	.top-index .dropdown-menu li .invoice-icon{background-position:-420px -13px}
	.top-index .dropdown-menu li .subscription-icon{background-position:-280px -13px}
	.top-index .dropdown-menu li .marketplace-icon{background-position:-304px -13px}
	.top-index .dropdown-menu li .e-Wallet-icon{background-position:-328px -13px}
	.top-index .dropdown-menu li .setting-icon{background-position:-352px -13px}
	.top-index .dropdown-menu li .log-out{background-position:-373px -13px}
	.navbar-nav .open .dropdown-menu>li>a:focus,.navbar-nav .open .dropdown-menu>li>a:hover{color:#fff!important;text-decoration:underline;background:0 0!important}
	.top-index .nav>li>a.dropdown-toggle.user{padding:0;margin:8px 0;line-height:34px}
}

@media screen and (max-width:1028px) and (min-width:768px) {
	.hot_sale {
		display: none;
	}
	.introduce {
		width: 85%;
		float: none;
		margin: 0 auto;
	}
	.common-pricing .coming-soon {
		font-size: 13px;
		margin-left: 0;
		display: block;
		padding: 3px 7px;
	}
}


.carousel-control.left, .carousel-control.right, .carousel-indicators{
	display: none;
}

.carousel-inner .item{
	float: left;
}

/*=====  End of Choices  ======*/
  .search-container {
	min-height: calc(100vh - 245px);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	background-size: cover;
	background-position: center center;
	padding: 15px;
  }
  
  .search-container form {
	width: 100%;
	max-width: 790px;
	margin: 0 auto;
	justify-content: center;
  }
  
  .search-container form legend {
	font-size: 36px;
	color: #000;
	font-weight: 800;
	text-align: center;
	margin-bottom: 50px;
	font-family: "Roboto","Helvetica Neue",Arial,sans-serif
  }
  
  .search-container form .inner-form {
	width: 100%;
	margin-bottom: 17px;
  }
  
  .search-container form .inner-form .input-field {
	width: 100%;
	position: relative;
  }
  
  .search-container form .inner-form .input-field .choices {
	margin-bottom: 0;
  }
  
  .search-container form .inner-form .input-field .choices .choices__inner {
	min-height: 70px;
	width: 100%;
	background: transparent;
	border: 0;
	background: #fff;
	display: block;
	width: 100%;
	padding: 10px 70px 10px 32px;
	font-size: 18px;
	color: #666;
	border-radius: 34px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	-ms-flex-align: center;
		align-items: center;
		
  }
  
  .search-container form .inner-form .input-field .choices .choices__inner .choices__list .choices__item {
	background: #e7e7e7;
	color: #333;
	line-height: 33px;
	border: 0;
	padding: 0 20px;
	font-size: 16px;
  }
  
  .search-container form .inner-form .input-field .choices .choices__inner .choices__list .choices__item .choices__button {
	border-left: 0;
	color: #b2b2b2;
	background-size: 14px auto;
	padding-left: 0;
	opacity: .3;
	transition: all .2s ease-out, color .2s ease-out;
  }
  
  .search-container form .inner-form .input-field .choices .choices__inner .choices__list .choices__item .choices__button:hover {
	opacity: 1;
  }
  
  .search-container form .inner-form .input-field .choices .choices__inner .choices__input {
	font-size: 16px;
	color: #333;
	background-color: transparent;
	margin-bottom: 0;
	border: 1px solid #aeaeae;
  }
  
  .search-container form .inner-form .input-field .choices .choices__list--dropdown {
	background: transparent;
	font-size: 16px;
	color: #fff;
	border: 0;
	padding: 0 32px;
  }
  
  .search-container form .inner-form .input-field .choices .choices__list--dropdown .choices__item {
	font-size: 13px;
	height: 25px;
	line-height: 1.3;
	padding: 0;
	padding-top: 5px;
	opacity: .7;
  }
  
  .search-container form .inner-form .input-field .btn-search {
	width: 70px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
		align-items: center;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	background: transparent;
	border: 0;
	padding: 0;
	cursor: pointer;
	display: flex;
	-ms-flex-pack: center;
		justify-content: center;
	align-items: center;
	min-width: inherit !important;
  }
  
  .search-container form .inner-form .input-field .btn-search svg {
	fill: #333;
	width: 40px;
	height: 40px;
	transition: all .2s ease-out, color .2s ease-out;
  }
  
  .search-container form .inner-form .input-field .btn-search:hover, .s004 form .inner-form .input-field .btn-search:focus {
	outline: 0;
	box-shadow: none;
  }
  
  .search-container form .inner-form .input-field .btn-search:hover svg, .s004 form .inner-form .input-field .btn-search:focus svg {
	fill: #000;
  }
  
  .search-container form .suggestion-wrap {
	padding: 0 15px;
  }
  
  .search-container form .suggestion-wrap span {
	font-size: 14px;
	font-family: 'Helvetica', sans-serif;
	display: inline-block;
	padding: 0 15px;
	line-height: 32px;
	color: #fff;
	border-radius: 16px;
	margin-right: 10px;
	margin-bottom: 10px;
  }

  .search-res{
	margin-top: 30px !important;
  }
  
  .search-res-box{
	border-radius: 4px;
	background: #aeaeae;
  }
  .search-res-box img{
	width: 100%;
	transform: scale(1);
	-webkit-transition: all .4s ease-out 0s;
	-moz-transition: all .4s ease-out 0s;
	-ms-transition: all .4s ease-out 0s;
	-o-transition: all .4s ease-out 0s;
	transition: all .4s ease-out 0s;
  }

  .mb-3{
	  margin-bottom: 30px;
  }

.top-index .navbar-default .search_nav>li>a{
	color: #3b396d !important;
}
.navbar-default .search_nav>li>a:before{
	background: #3b396d !important;
}

.img-invert{
	filter: invert(100%);
}