.card {
  position: relative;
  width: 90%;
  margin: auto;
}
.card .form-group label {
  font-size: 18px;
  font-family: sans-serif;
  font-weight: bolder;
}
span {
  font-size: 15px;
  font-weight: bold;
}
.show-image {
  position: relative;
  margin-top: 6px;
}
.show-image1 {
  position: relative;
  margin-top: 6px;
}
.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}
.img-overlay1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}
.show-image:hover .img-overlay {
  display: block;
  background: rgba(0, 0, 0, 0.4);
}
.show-image1:hover .img-overlay1 {
  display: block;
  background: rgba(0, 0, 0, 0.4);
}
.images {
  position: relative;
  width: 200px;
  height: 200px;
}
.images1 {
  position: relative;
  width: 100%;
  height: 90px;
}
.closeButton {
  z-index: 1;
  position: absolute;
  text-align: center;
  color: black;
  background-color: white;
  font-size: 18px;
  width: 90px;
  top: 40%;
  left: 30%;
  transition: opacity 0.35s ease;
  opacity: 0;
}
.closeButton1 {
  z-index: 1;
  position: absolute;
  text-align: center;
  color: red;
  background-color: white;
  font-size: 20px;
  top: 0;
  right: 0;
  transition: opacity 0.35s ease;
  opacity: 0;
  padding: 3px;
  cursor: pointer;
}
.show-image:hover .closeButton {
  opacity: 1;
}
.show-image1:hover .closeButton1 {
  opacity: 1;
}
.custom-tooltip {
  position: relative;
  z-index: 9;
  cursor: pointer;
}
.custom-tooltip::before,
.custom-tooltip::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #333;
  z-index: 9;
  position: absolute;
  top: -0.25rem;
  left: 32px;
  cursor: pointer;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}

.custom-tooltip::before {
  --translate-y: calc(-100% - var(--arrow-size));
 z-index: 9;
  content: attr(data-tooltip);
  text-transform: lowercase;
  color: white;
  padding: 0.5rem;
  border-radius: 0.3rem;
  text-align: center;
  min-width: 250px;
  max-width: 100%;
  background: var(--tooltip-color);
}

.custom-tooltip:hover::before,
.custom-tooltip:hover::after {
  --scale: 1;
  z-index: 9;
}

.custom-tooltip::after {
  --translate-y: calc(-1 * var(--arrow-size));
  z-index: 9;
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}
.parentDisable {
  background-color: #efefef;
  position: relative;
  width: auto;
  height: auto;
  z-index: 9998;
  opacity: 0.5;
  filter: alpha(opacity=50);
  pointer-events: none;
}

.nav-tabs li {
  font-size: 15px;
  font-weight: 500;
}

.ql-toolbar,
.ql-container,
.react-tagsinput {
  border-radius: 4px;
}

.show-image1 .images,
.tab-content img {
  object-fit: cover;
  width: 100%;
}

.closeButton1 {
  right: 15px !important;
}
.show-image1:hover .img-overlay1,
.img-overlay1 {
  opacity: 0;
}

.btn_new {
  background-color: #ffa000 !important;
  color: #fff !important;
  border: 0 !important;
  font-weight: 500 !important;
  height: 40px !important;
  min-width: auto !important;
  text-transform: uppercase;
  opacity: 1 !important;
}

.mainBox {
  min-height: calc(100vh - 122px);
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  height: 40px;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  padding: 0 !important;
  height: 100% !important;
  font-size: 14px !important;
}

.MuiSvgIcon-root {
  font-size: 2.5em !important;
  position: absolute !important;
  top: -19px !important;
}

.card h6 span {
  color: #1dc7ea;
  font-size: 12px;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif !important;
}
