@media only screen and (max-width: 768px) {
  .signup-form .sign-up-form {
    width: 100% !important;
  }
}

.signup-form {
  background-size: cover;
  background-position: center;
  height: 800px;
  background-image: url("https://cdn.shopify.com/s/files/1/0317/5999/0924/files/Bg-1_1_1920x1080_crop_center.jpg?v=1628852253");
}
.text-content-box{
  border: 1px solid red;
}
.sign-up-form {
  display:flex;
  flex-direction: column;
  justify-content: center;
  width: 35%;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  background: #E8EBF1;
  padding: 15px;
  text-align: center;
  border-radius: 15px;
 
  top: inherit;
  position: absolute;
  right: 100px;
  align-items: center;
  /* border: 1px solid red; */
}

.sign-up-form-div{
  display: flex;
  gap: 10px;
  justify-content: center;
  /* border: 1px solid red; */
  text-align: center;
}

.sign-up-form h1 {
  color: #000;
  font-weight: 500;
  margin-bottom: 30px;
  font-size: 42px;
}

.input-box {
  margin: 10px 0;
background-color: #E8EBF1;
  border: 1px solid #999;
  outline: none;
  width: 100%;
 
  /* border: 1px solid red; */
  margin: 8px;
  height: 40px;
}

.check {
  padding: 10px;
}

.btn-fill {
  background-color: rgb(255, 160, 0);
  color: rgb(255, 255, 255);
  font-weight: 500;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 0px;
  font-size: 15px;
}

.signup-btn:hover {
  background-color: #ffa000;
}

body,
.signup-form {
  min-height: 100vh;
}

.input-box PhoneInput {
  padding: 0px !important;
}

input {
  padding: 10px 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #d6d1d5;
}

.PhoneInputCountry {
  padding-left: 15px;
}

.PhoneInputInput {
  border: 0px;
  flex: inherit;
}

.css-yk16xz-control {
  border: 1px solid #999 !important;
}

.fullName label {
  display: none;
}

.css-1wa3eu0-placeholder {
  color: inherit !important;
}

.signup-form p {
  color: grey;
}

.signup-form #msform {
  text-align: center;
  position: relative;
  margin: 0;
 
}

.signup-form #msform fieldset {
  background: transparent;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 95%;
  margin: 0;
 
  position: relative;
}

.signup-form .form-card {
  text-align: left;
}

.signup-form #msform fieldset:not(:first-of-type) {
  display: none;
}

.signup-form #msform input,
.signup-form #msform textarea {
  padding: 8px 15px 8px 15px;
  border: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #2c3e50;
  background-color: transparent;
  font-size: 16px;
  border-radius: 4px;
}

.signup-form #msform input:focus,
.signup-form #msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #673ab7;
  outline-width: 0;
}

.signup-form #msform .action-button {
  width: 100px;
  background: rgb(255, 160, 0);
  border-radius: 4px;
  color: white;
  border: 0 none;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 0px 10px 5px;
  float: right;
  text-transform: uppercase;
}

.signup-form #msform .action-button:hover,
.signup-form #msform .action-button:focus {
  background-color: rgb(255, 160, 0);
}

.signup-form #msform .action-button-previous {
  width: 100px;
  background: #616161;
  color: white;
  border: 0 none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px 10px 0px;
  float: left;
}

.signup-form #msform .action-button-previous:hover,
.signup-form #msform .action-button-previous:focus {
  background-color: #000000;
}

.signup-form .card {
  z-index: 0;
  border: none;
  position: relative;
}

.signup-form .fs-title {
  font-size: 20px;
  color: #000;
  margin: 15px 0 15px;
  font-weight: normal;
  text-align: left;
}

.signup-form .purple-text {
  color: #673ab7;
  font-weight: normal;
}

.signup-form .steps {
  font-size: 20px;
  color: gray;
  margin: 15px 0 15px;
  font-weight: normal;
  text-align: right;
}

.signup-form .fieldlabels {
  color: gray;
  text-align: left;
}

.signup-form #progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
  width: 100%;
  padding: 0px;
  margin: 0px 15px;
}

.signup-form #progressbar .active {
  color: rgb(255, 160, 0);
}

.signup-form #progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
}

.signup-form #progressbar #account:before {
  content: "1";
}

.signup-form #progressbar #personal:before {
  content: "2";
}

.signup-form #progressbar #payment:before {
  content: "3";
}

.signup-form #progressbar #confirm:before {
  content: "4";
}

.signup-form #progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

.signup-form #progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

.signup-form #progressbar li.active:before,
.signup-form #progressbar li.active:after {
  background: rgb(255, 160, 0);
}

.signup-form .progress {
  height: 20px;
}

.signup-form .progress-bar {
  background-color: #673ab7;
}

.signup-form .fit-image {
  width: 100%;
  object-fit: cover;
}

.pos-rel {
  position: relative;

}

.pos-abs-msg {
  position: absolute;
  bottom: 5px;
  font-size: 12px;
}

input[placeholder]:focus {
  outline: none !important;
  border: 1px solid #ccc !important;
}
