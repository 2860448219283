#banner{
  padding-top: 0;
  margin-bottom: 100px;
}
.head-img{
  height:100px;
}
.sup-input-box {
  border-radius: 20px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #999;
  /* outline: none; */
  width: 100%;
}
.sup-input-box:focus {
  border: 1px solid #256797 !important;
}

.check {
  padding: 10px;
}
.tab-hr {
  border: 2px solid green;
  border-radius: 2px;
  margin-top: -5px;
}
.sup-btn {
  color: #fff;
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  font-size: 15px;
  margin: 10px 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #1c8adb;
}

.sup-btn:hover {
  background-color: #1c8adb;
}
