

.footer-top-layout [class*="col-sm-"]:first-child {
 padding: 0;
}

.footer-top-layout {
 color: #ffffff;
}
.footer-col-item {
 min-height: 140px;
}

address {
 color: #545454;
}



.footer-top-layout {
 color: #ffffff;
}

.footer-top-layout h4 {
 color: #ffffff;
 font-size: 18px;
 margin-bottom: 21px;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
 color: #333333;
 font-family: "Roboto",sans-serif;
 font-weight: 500;
 line-height: 1.1;
 margin-top: 0;
}

.footer-col-item .item-contact a {
 color: #545454;
 display: inline-block;
 margin-bottom: 7px;
 width: 100%;
}

.footer-col-item .item-contact a .link-id {
 color: #ffffff;
 margin-left: 0;
 margin-right: 6px;
}
.footer-col-item span {
 margin-left: 7px;
}
.footer-col-item .item-contact a {
 color: #545454;
}

.footer-top-layout h4 {
 color: #ffffff;
 font-size: 18px;
 margin-bottom: 21px;
}

.signUpNewsletter .gt-email {
 background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
 border: 1px solid #666666;
 border-radius: 0;
 color: #ffffff;
 height: 52px;
}
.signUpNewsletter .btn-go {
 background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
 border-color: #666666;
 border-style: solid;
 border-width: 0 0 0 1px;
 bottom: 0;
 color: #ffffff;
 font-size: 16px;
 position: absolute;
 right: 0;
 text-transform: uppercase;
 top: 0;
 width: 52px;
}
.footer-bottom-layout {
 background: #222222 none repeat scroll 0 0;
 padding: 20px 0;
 text-align: center;
 color:white;
}

.socialMedia-footer {
 margin-bottom: 20px;
}
.socialMedia-footer > a {
 display: inline-block;
 margin: 0 8px;
}
.footer-bottom-layout {
 text-align: center;
}

.footer-social a{
  float: right;
  font-size: 20px;
  margin-right: 20px;
  color: white;
}
.footer-img{
  width: 200px;
position: relative;
bottom: 70px;

}
.footer-company a{
  float: left;
  margin-right: 20px;
  color: white;

}
