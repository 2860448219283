body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.uploadBtn{
 display: flex;
 flex-direction: row-reverse;
 padding: 15px 40px ;
 width:250px;
}
/* Modal box css*/
.styles_closeButton__20ID4 {
  right: 8px !important;
  display: flex;
  flex-direction: row-reverse;
 
}
.styles_modal__gNwvD {
  max-width: 800px;
  position: relative;
  padding: 1.2rem;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  margin: 0 auto;
  top: 60px;
}

.css-yk16xz-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  background-color: #E8EBF1 !important;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0!important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}
.toast-message {
  background-color: red;
}

/* Download button css */

.excelDownload{
  /* border: 1px solid red; */
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.buttonDownload {
	display: inline-block;
	position: relative;
	padding: 10px 25px;
  
	background-color: #FFA000;
	color: white;
  border-radius: 5px;
	font-family: sans-serif;
	text-decoration: none;
	font-size: 0.9em;
	text-align: center;
	text-indent: 15px;
}

.buttonDownload:hover {
	background-color: #333;
	color: white;
}

.buttonDownload:before, .buttonDownload:after {
	content: ' ';
	display: block;
	position: absolute;
	left: 15px;
	top: 52%;
}

/* Download box shape  */
.buttonDownload:before {
	width: 10px;
	height: 2px;
	border-style: solid;
	border-width: 0 2px 2px;
}

/* Download arrow shape */
.buttonDownload:after {
	width: 0;
	height: 0;
	margin-left: 3px;
	margin-top: -7px;
  
	border-style: solid;
	border-width: 4px 4px 0 4px;
	border-color: transparent;
	border-top-color: inherit;
	
	
}

.buttonDownload:hover:before {
	border-color: #FFA000;
}

.buttonDownload:hover:after {
	border-top-color: #FFA000;
	animation-play-state: running;
}

/* keyframes for the download icon anim */



/* CSS */
.button-24 {
  background: #ffa000;
  border: 1px solid #ffa000;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 14px;
  font-weight: 400;
  /* line-height: 16px; */
  height: 25px;
  outline: 0;
  padding: 0px 5px 0px 5px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.button-24:hover,
.button-24:active {
  background-color: initial;
  background-position: 0 0;
  color: #ffa000;
}

.button-24:active {
  opacity: .5;
}

.search_box{
  height:25px;
}

.new-css .header{
  padding: 5px 5px 0 !important;
}
.new-css{
  height: 410px;
  /* border: 2px solid red; */
}
@media only screen and (min-width: 1400px) {
  .new-css{
    height: 450px;
    /* border: 2px solid red; */
  }
}
.new-content{
  border:"2px solid red" !important;
}
.card .header{
  padding: 5px 5px 0 !important;
}
body>.navbar-collapse .logo {
  padding: 10px 14px !important;
  border-bottom: 1px solid hsla(0,0%,100%,.2);
}
.green{
  background-color: green;
}
.yellow{
  background-color: rgb(255, 196, 0);
}
.lightyellow{
background-color: #00D247;
}
.lightblue{
  background-color: #2669FF;
}
#color_white1{
  color: #fff!important;
}
#color_white2{
  color: #fff!important;
}
#color_white3{
  color: #fff!important;
}
#color_white4{
  color: #fff!important;
}
#topCustomer{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  height: 420px;
  border-radius: 10px;
}
#card1{
  margin-top: 10px;
}
.card2{
  margin-top:45px!important;
}
#cardid{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px!important;
  border-radius: 10px;
}

/* tracking modal css */
.styles_modal__gNwvD {
  max-width: 90% !important;
  width: 50% !important;
}
.RSPBprogressBar .RSPBstep #step1{
  left:6% !important;
}
.styles_closeButton__20ID4{
  right: 0px !important;
}

.list {
  user-select: none;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 16px;
}
.merchantStore .card{
  border: none !important;
}
.item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(84, 84, 241);
  color: white;
  height: 150px;
  cursor: grab;
  font-size: 20px;
  user-select: none;
}


.supplier_dashboard_card{
   height:400px !important;
   border: 2px solid red;
}

.dragged {
  background-color: rgb(37, 37, 197);
}
@media (min-width: 1640px) {
  .new-css{
    height: 410px;
   /* border: 2px solid red;  */
  }
  
}
@media (min-width: 600px) {
  .list {
    grid-gap: 24px;
  }
}

.sidebar:after{
  background: #670975!important;
}
#propnameStyle{
  font-style: normal!important;
  margin: 4px!important;
}
.styles_modal__gNwvD{
  border-radius: 5px!important;
  background-color: lavender!important;
  width: 40% !important;
  top: 40% !important;
}
#cardContentClass:hover{
  color: black !important;
}