p,
div {
  font-family: Lato;
}

/*
  Colors:
  https://coolors.co/22223b-4a4e69-eadae4-9a8c98-c9ada7
*/
/*
YANKEES BLUE: #22223B
INDEPENDENCE: #4A4E69
PLATINUM: #EADAE4
SPANISH GRAY: #9A8C98
SILVER PINK: #C9ADA7
*/

#wrapper-login {
  background-size: cover;
  background-position: center;
  
  background-image: url("https://cdn.shopify.com/s/files/1/0317/5999/0924/files/Bg-1_1_1920x1080_crop_center.jpg?v=1628852253");
}

#form-wrapper-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  background: #e8ebf1;
  padding: 15px;
  text-align: center;
  border-radius: 15px;
  height: 70%;
  top: 70px;
  position: absolute;
  right: 100px;
  /* border: 1px solid red; */
}

/* #form-wrapper-login > h2 {
  display: flex;
  justify-content: center;
  font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 2em;
  font-weight: lighter;
  margin-top: 0.25em;
  color: #222;
  border: 1px solid red;
} */

form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.info {
  padding-bottom: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

/* label {
  margin-bottom: 0.5em;
  color: #E8EBF1;
  font-weight: lighter;
} */

.input-box-login {
  background-color: #e8ebf1;
  border: 1px solid #999;
  outline: none;
  width: 100%;
  text-align: left;
  /* border: 1px solid red; */
  /* margin: 8px; */
  height: 40px;
}

/* input {
  padding: 10px 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #d6d1d5;
} */

input::placeholder {
  font-size: 1.2em;
  font-weight: lighter;
  color: #bbb;
}

button {
  min-width: 100%;
  cursor: pointer;
  margin-right: 0.25em;
  margin-top: 0.5em;
  padding: 0.938em;
  border: none;
  border-radius: 4px;
  background-color: #22223b;
  color: #fefefe;
}
button:hover {
  background-color: #4a4e69;
  color: #fefefe;
}

.error {
  color: #db2269;
  font-size: 0.5em;
  display: relative;
}

.email,
.password {
  width: 100%;
}

.submit-login {
  width: 100%;
  margin-top: 5px;
}
