.product-logo {
  width: 50%;
  height: 10%;
}

.styles_closeButton__20ID4 {
  right: 0;
}
button:hover {
  background-color: white;
}

.card-update {
  position: relative;
  width: 70%;
  margin: auto;
  padding: 1em;
}
.card-btn-update {
  margin-left: auto;
}

.card {
  position: relative;
  width: 100%;
  margin: auto;
}

.card label {
  color: #2f4f4f !important;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-weight: bolder !important;
}

.card .form-group label.btn {
  font-size: 14px;
  color: #fff !important;
  font-weight: 500 !important;
  opacity: 1;
  margin: 0;
  margin-right: 20px;
  height: 40px;
  line-height: 24px;
}
