.container {
  font: 14px/1.5 "Helvetica" !important;
  color: #333;
  -webkit-text-size-adjust: 100%;
  width: 1200px;
  margin: 0 auto;
}
.productPanel {
  margin-top: 40px;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

.panelBody {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.galleryImg {
  margin-right: 20px;
}

.galleryPreview {
  float: left;
  text-align: center;
  width: 500px;
}

.zoomImg {
  width: 500px;
  height: 500px;
  position: relative;
  padding: 0;
}

.zoomImg img {
  width: 100%;
}

.galleryList {
  width: 500px;
  height: 80px;
  margin-top: 20px;
  overflow: hidden;
  text-align: center;
}

.galleryListItems {
  float: left;
  margin-right: 22px;
  display: inline;
  width: 78px;
  height: 78px;
  border: 1px solid #7b999e;
}

.productInfo {
  float: right;
  width: 680px;
}

.productInfo h3 {
  padding: 0 20px;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
}

.productPriceList {
  margin-top: 25px;
  background: #ebf5f3;
  padding: 20px;
}

.productPriceList ul,
.productSpec ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.productPriceList ul li {
  width: 50%;
  height: 44px;
  margin-top: 15px;
  line-height: 30px;
  float: left;
  font-size: 14px;
}

.productPriceList ul li span {
  display: inline-block;
  width: 85px;
  margin-right: 40px;
  color: #7b999e;
  line-height: 20px;
}

.productPriceList ul li em {
  font-size: 32px;
  color: #000;
  display: inline-block;
  font-style: normal;
}

.productSpec {
  padding: 20px;
}

.productSpec ul li {
  width: 100%;
  float: left;
  margin-top: 20px;
  line-height: 14px;
  font-size: 14px;
}

.productSpec ul li span {
  display: inline-block;
  width: 130px;
  margin-right: 25px;
  color: #7b999e;
  float: left;
}

.productSpec ul li em {
  display: inline-block;
  color: #333;
  float: left;
  font-style: normal;
}

.productSpec ul .quantity em {
  width: 32px;
  height: 32px;
  float: left;
  border: 1px solid #7b999e;
  cursor: pointer;
}

.productSpec ul .quantity input {
  width: 60px;
  height: 32px;
  float: left;
  padding: 0;
  margin: 0 5px;
  border-radius: 0;
  border: 1px solid #7b999e;
  line-height: 32px;
  text-align: center;
}

.productSpec ul .quantity .minus {
  background: url("/minus.png") no-repeat center;
}

.productSpec ul .quantity .add {
  background: url("/plus.png") no-repeat center;
}

.productBtn {
  width: 145px;
  height: 40px;
  margin-left: 20px;
  /* line-height: 40px; */
  border: none;
  font-size: 14px;
  color: #fff;
  border-radius: 4px;
  -webkit-appearance: button;
  cursor: pointer;
}

.lightBlue {
  background-color: #54b9eb;
  text-decoration: none;
  transition: 0.3s;
}
.lightBlue:hover {
  background-color: #0f83bc;
}

.darkBlue {
  background-color: #19489e;
  text-decoration: none;
  transition: 0.3s;
}
.darkBlue:hover {
  background-color: #453d86;
}

.productBtn svg {
  display: block;
  float: left;
  fill: #fff;
}

.productBtn .subsribe-svg {
  width: 14px;
  height: 14px;
  margin: 13px 0 0 15px;
}

.productBtn .addcart-svg {
  width: 18px;
  height: 18px;
  margin: 11px 0 0 23px;
}

.productDesc {
  border: 1px solid #e8e8e8;
}

.productPanel .panelHeader {
  position: relative;
  background-color: #f8fafb;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #e1e8f0;
}

.productPanel .panelHeader h3 {
  padding: 25px 40px;
  font-size: 20px;
  font-weight: 700;
  color: #7b999e;
  margin: 0;
}

.productDesc .panelBody {
  border-radius: 0 0 4px 4px;
  color: #7b999e;
  padding: 25px 40px;
  display: inherit;
  flex-direction: inherit;
}

.productDesc .panelBody b {
  display: block;
  margin: 15px 0;
  font-weight: 700;
}

em {
  font-style: normal;
}
p {
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: block;
  content: " ";
  clear: both;
}

.single_product_cont .owl-carousel.owl-drag .owl-item,
.single_product_cont .owl-carousel.owl-drag .item {
  width: calc((100% / 5) - 131) !important;
  height: auto !important;
  margin: 0 !important;
  margin-right: 10px !important;
  border-radius: 0;
  background: none;
  box-shadow: none;
  /* text-align: center; */
  /* vertical-align: middle; */
  position: relative;
  min-height: 1px;
  border: 1px solid;
}
