.sidebar .nav li > a{
    margin: 5px 0 !important;
}

.merchantStore .card{
    width: 100% !important;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
}

.merchantStore .card .card-body{
    padding: 15px 15px 10px;
}

.MuiButton-label, table, table th{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

form{
    margin-bottom: 20px;
}

.bdycontent{
    height: calc(100vh - 123px);
}

footer{
    clear: both;
}

.main-panel > .content{
    overflow: auto;
}