/* change the background color */
.LandingPage .header1 .navbar-custom {
    background-color: #ffffff;
    border-bottom: #114169 1px solid;
}
/* change the brand and text color */
.LandingPage .header1 .navbar-custom .navbar-brand,
.LandingPage .header1 .navbar-custom .navbar-text {
    color: #114169;
}
/* change the link color */
.LandingPage .header1 .navbar-custom .navbar-nav .nav-link {
    color: #114169;
}
/* change the color of active or hovered links */
.LandingPage .header1 .navbar-custom .nav-item.active .nav-link,
.LandingPage .header1 .navbar-custom .nav-item:focus .nav-link,
.LandingPage .header1 .navbar-custom .nav-item:hover .nav-link {
    color: #114169;
}
.LandingPage .header1 .navbar-custom .navbar-toggler-icon{
    background-color: #114169;
}