.addware-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-wrapper {
  display: flex;
  flex-direction: column;
  width: 75%;
  max-width: 75%;
  /* min-width: 100px; */
  min-height: 400px;
  padding: 20px 40px;
  border-radius: 6px;
  /* box-shadow: 0px 8px 36px #222; */
  background-color: #fefefe;
}
.form-wrapper > h2 {
  display: flex;
  justify-content: center;
  font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 2em;
  font-weight: lighter;
  margin-top: 0.25em;
  color: #222;
}

form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
label {
  margin-bottom: 0.5em;
  color: rgb(54, 53, 53);
  font-weight: lighter;
  font-size: medium;
}

input {
  padding: 10px 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #d6d1d5;
}

input::placeholder {
  font-size: 1.2em;
  font-weight: lighter;
  color: #bbb;
}

button {
  min-width: 100%;
  cursor: pointer;
  margin-right: 0.25em;
  margin-top: 0.5em;
  padding: 0.938em;
  border: none;
  border-radius: 4px;
  background-color: #22223b;
  color: #fefefe;
}
button:hover {
  background-color: #4a4e69;
  color: #fefefe;
}
.fullName {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding: 15px 15px 15px 15px;
  width: 100%;
}

#code {
  width: 70%;
}
.submit {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
p,
div {
  font-family: Lato;
}
#nameandaddress {
  display: flex;
  flex-direction: row;
  width: 100%;
}
#button {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
