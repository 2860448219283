.subHead{
  width: 75%;
margin-right: 12%;
margin-left: 12%;

}


.haeding{
  margin-top: 50px;
  margin-bottom: 30px;
}

  .controls{
    margin-right: 10%;
margin-left: 10%;
width: 100%;
  }

  
